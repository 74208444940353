// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from "./pages/Home";
import OurTeam from "./pages/OurTeam";
import Positions from "./pages/Positions";
import Services from './pages/Services';
import Contact from './pages/Contact';

const App = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Router >
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/team" element={<OurTeam />} />
          <Route path="/services" element={<Services />} />
          <Route path="/positions" element={<Positions />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
