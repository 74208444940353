import React from "react";
import EmployeeCard from "../components/EmployeeCard";

const employees = [
  {
    image: 'Pohlman_Dave.jpg',
    name: 'Dave Pohlman',
    title: 'President',
    phone: '(630) 802-8248',
    email: 'dpohlman@capitalsearchinc.com',
    bio: "Dave is a seasoned recruiter with over 13 years direct experience. He has successfully placed candidates in the following positions/industries: Engineering, Project Controls, Director/Executive Level, Business Development/Sales, HR, Talent Acquisition/Recruiting, Marketing, Finance and IT. He specializes in full life-cycle recruiting, talent acquisition, identifying passive candidates, executive search, strategic planning for recruitment, and applicant tracking.",
  },
  {
    image: 'Mehmen_Jason.jpg',
    name: 'Jason Mehmen',
    title: " Vice President of Recruiting Operations",
    phone: '(480) 650-1798',
    email: 'jmehmen@capitalsearchinc.com',
    bio: "Jason has 20 years of experience in technical recruiting, communications, and human resource management. Jason brings a wide spectrum of experience to the Capital Search Team.  Jason’s depth of understanding of both internal and external factors in recruiting and human resource management is an asset to our clients and the candidates we represent.",
  },
  {
    image: 'Slamowitz_Louis.jpg',
    name: 'Lou Slamowitz',
    title: 'Managing Director',
    phone: '(630) 878-9188',
    email: 'lslamowitz@capitalsearchinc.com',
    bio: "Louis has 18 years of experience with Direct Placement, Retained Search, and Staffing Experience. He has worked with retained clients and has exclusive relationships with some of the top engineering and consulting firms in the world.",
  },
  {
    image: 'Henriksen_John.jpg',
    name: 'John Henriksen',
    title: 'Senior Executive Recruiter',
    phone: '(773) 329-3124',
    email: 'j.henriksen@capitalsearchinc.com',
    bio: "John Henriksen graduated from Illinois State University in 2005 with a bachelor's degree in marketing and an emphasis in professional sales.  After graduation, John began his professional career as a Technical Recruiter with Apex Systems, now a division of ASGN.  John spent over 19 years with Apex during which he operated in the capacity of Senior Professional Recruiter and Executive Delivery Manager.  His passion lies in acting as a facilitator between candidates and employers.  John is a firm believer in direct communication, treating others with the highest levels of respect, and making decisions based on integrity and ethics.  On a personal level, John is blessed with three children including two sons and one daughter.  When John is not spending time with family, he can be found taking his labrador retriever for walks, fishing, or spending time helping friends and family with everyday tasks.  As a member of Capital Search Inc., John is committed to best-in-class experiences for both candidates and clients.",
  },
  {
    image: 'DuPree_Danielle.jpg',
    name: 'Danielle DuPree',
    title: "Senior Executive Recruiter",
    phone: '(850) 567-3400',
    email: 'ddupree@capitalsearchinc.com',
    bio: "Danielle is a Senior Talent Acquisition Specialist with over 14 years' experience in recruitment, sales and people management, with the last six of which supporting all facets of the power delivery industry. Her strengths lie in her energy, passion, and ability to connect and represent candidates in the most effective way possible.",
  },
];

const OurTeam = () => {
  return (
    <div className="mt-12">
      <div className="pb-8 flex flex-wrap justify-center text-4xl text-company-900 underline">
        <h1>Our Team</h1>
      </div>
      <div className="flex flex-wrap justify-center">
        {employees.map((employee, index) => (
          <EmployeeCard key={index} employee={employee} />
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
