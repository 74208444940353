import React from "react";

const NewsCard = ({ image, title, summary, link }) => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white border border-gray-200">
      {/* Image Section */}
      <img
        src={"/images/" + image}
        alt={title}
        className="w-full h-48 object-cover"
      />

      {/* Content Section */}
      <div className="p-4">
        {/* Title */}
        <h3 className="text-lg font-bold mb-2 text-company-800">{title}</h3>

        {/* Summary */}
        <p className="text-sm text-gray-600 mb-4">{summary}</p>

        {/* Link */}
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-company-800 font-medium hover:underline"
        >
          Read More
        </a>
      </div>
    </div>
  );
};

export default NewsCard;
