import React from "react";

const PositionCard = ({ title, description }) => {
  return (
    <div className="max-w-md p-4 border rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
      <h2 className="text-xl font-bold text-company-gold-800">{title}</h2>
      <p className="text-gray-600 mb-4">
        {description}
      </p>
      <a
        href={"mailto:dpohlman@capitalsearchinc.com"}
        className="inline-block px-4 py-2 text-white bg-company-800 rounded-lg hover:bg-company-600 transition-colors duration-300"
        target="_blank"
        rel="noopener noreferrer"
      >
        Send Resume
      </a>
    </div>
  );
};

export default PositionCard;
