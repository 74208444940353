// src/components/Footer.js
import React from 'react';
import LinkedIn from '../assets/linkedin.svg';
import Twitter from '../assets/twitter.svg'

const Footer = () => {
  return (
    <footer className="static bg-company-900 p-4 mt-8">
      <div className='flex justify-between'>
        <div className='flex'>
          <a href="https://www.linkedin.com/company/capital-search-inc">
            <img src={LinkedIn} alt="" className="h-6 px-2" />
          </a>
          <a href="https://twitter.com/capital_search">
            <img src={Twitter} alt="" className="h-6 px-2" />
          </a>
        </div>
        <p className="text-center text-company-100">© 2024 Capital Search, Inc. All rights reserve.</p>
      </div>
    </footer>
  );
};

export default Footer;
