import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/capital-search-logo.png'; // Import the logo image

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false); // State to track scroll position

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path || (location.pathname.endsWith('/') && path === location.pathname.slice(0, -1));
  };

  // Add scroll listener to detect when the user scrolls down
  useEffect(() => {
    const handleScroll = () => {
      // Update `isScrolled` when the user scrolls down
      setIsScrolled(window.pageYOffset > 0);
    };

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`sticky top-0 px-8 py-2 transition-colors duration-800 ${isScrolled ? 'backdrop-blur shadow-md' : 'bg-transparent'
        }`}
    >
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img src={Logo} alt="Capital Search Logo" className="h-16" />
        </Link>

        {/* Hamburger Icon */}
        <button
          onClick={toggleMenu}
          className="text-company-800 md:hidden focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Menu Links */}
        <div
          className={`${isMenuOpen ? 'block' : 'hidden'
            } md:flex space-x-6 text-company-800`}
        >
          <Link
            to="/"
            className={`py-2 px-3 ${isActive('/') ? 'border-b-2 border-company-800' : 'hover:border-b-2 border-company-800'
              }`}
          >
            Home
          </Link>
          <Link
            to="/team"
            className={`py-2 px-3 ${isActive('/team') ? 'border-b-2 border-company-800' : 'hover:border-b-2 border-company-800'
              }`}
          >
            Our Team
          </Link>
          <Link
            to="/services"
            className={`py-2 px-3 ${isActive('/services') ? 'border-b-2 border-company-800' : 'hover:border-b-2 border-company-800'
              }`}
          >
            Services
          </Link>
          <Link
            to="/positions"
            className={`py-2 px-3 ${isActive('/positions') ? 'border-b-2 border-company-800' : 'hover:border-b-2 border-company-800'
              }`}
          >
            Positions
          </Link>
          <Link
            to="/contact"
            className={`py-2 px-3 ${isActive('/contact') ? 'border-b-2 border-company-800' : 'hover:border-b-2 border-company-800'
              }`}
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
