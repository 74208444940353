import React from "react";
import PositionCard from "../components/PositionCard";

const Positions = () => {
  const positions = [
    {
      title: "Distribution",
      description: "Oversee the planning and operation of power distribution systems.",
    },
    {
      title: "Substation",
      description: "Design and manage substation projects to ensure reliable energy delivery.",
    },
    {
      title: "Transmission",
      description: "Develop and maintain high-voltage transmission systems for energy transfer.",
    },
    {
      title: "Program ​Management",
      description: "Lead and coordinate energy projects to achieve strategic objectives.",
    },
    {
      title: "Chief Engineer",
      description: "Provide technical leadership for energy infrastructure development.",
    },
    {
      title: "Distribution Engineer",
      description: "Design and optimize power distribution networks for efficiency and reliability.",
    },
    {
      title: "P&C Engineer",
      description: "Develop protection and control systems to ensure grid safety and stability.",
    },
  ];

  return (
    <div className="p-12">
      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {positions.map((position, index) => (
          <PositionCard
            key={index}
            title={position.title}
            description={position.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Positions;
