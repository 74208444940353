import React from "react";
import Power from "../assets/power-delivery.svg";
import Gas from "../assets/gas.svg"

const Services = () => {
  return (
    <div className="py-8 px-12">
      <div className="py-8">
        <div className="flex justify-center text-4xl text-company-800 underline">
          <h1>Markets We Serve</h1>
        </div>
        <div className="flex justify-between items-center">
          <div className="w-1/2 text-company-800">
            <h1 className="pb-12 text-3xl text-company-800 underline">Power Delivery</h1>
            <p>The electric power industry is the backbone of America’s economic sectors, generating the energy that empowers its people and businesses in global commerce. Transportation, water, emergency services, telecommunications, and manufacturing represent only a few of the power grid’s critical downstream dependencies. The United States has one of the world’s most reliable, affordable, and increasingly clean electric systems, along with the popular transition to smart, data-driven technologies which aims to increase power grid efficiency and engage customer reliability roles.</p>
          </div>
          <img src={Power} alt="Power Delivery" className="w-1/2 max-w-full h-auto" />
        </div>
        <div className="pt-16 flex justify-between items-center">
          <img src={Gas} alt="Gas and Oil" className="w-1/2 max-w-full h-auto" />
          <div className="w-1/2 text-company-800">
            <h1 className="pb-12 text-3xl text-company-800 underline">Oil & Gas</h1>
            <p>Oil and natural gas are major industries in the energy market and play an influential role in the global economy as the world's primary fuel sources. The processes and systems involved in producing and distributing oil and gas are highly complex, capital-intensive, and require state-of-the-art technology.</p>
          </div>
        </div>
      </div>
      <div className="pt-12">
        <div className="flex justify-center text-4xl text-company-800 underline">
          <h1>Services We Offer</h1>
        </div>
        <div className="pt-8 text-company-800">
          <div className="px-2 text-company-800">
            <h1 className="pb-4 text-2xl text-center">Contingent & Retained Search</h1>
            <p>Capital Search engages in both search models, but typically works more in the retained search space as trusted advisors to its clients. Every organization has a unique mission, culture, and hiring needs, so it’s important to find a retained search firm that is strategically positioned in your industry to access the right people. Executive-level employees are key decision-makers who directly influence the culture and bottom line of the organization.</p>
            <p className="pt-4">Retained search firms have access to a large database of highly qualified candidates and a network of industry leaders that is most likely unavailable to a contingent firm. This access greatly increases your chances of finding the best possible leader for your company. They're also more invested in the client relationship and the success of the search, so they are committed to finding the candidate who is the perfect fit for your organizational culture and for the specific position. At Capital Search, this means candidates undergo an extensive vetting process involving screening, video interviews, front-runner interviews with the client, a final interview, and an onboarding service with leadership coaching.</p>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-1/2 px-2 text-company-800 text-center">
              <h1 className="py-12 text-2xl text-company-800">Retained Search</h1>
              <p>Retained search relationships are typically more shoulder-to-shoulder advisory work, where time and resources are invested to deeply understand a client’s business. Also discussed are the inner workings of companies, what their strategic objectives are, the thought processes of upper management, how the companies are structured, where the person or people will fit inside the organization, and what the prevailing culture is. Because of this more rigorous work, retained firms generally temper the number of clients they can correctly service at any given time. Whereas retained relationships tend to evolve into long-term partnerships, contingency client relationships tend to be shorter-term and task-focused.</p>
            </div>
            <div className="w-1/2 px-2 text-company-800 text-center">
              <h1 className="pb-12 text-2xl text-company-800">Contingent Search</h1>
              <p>Contingent searches are not exclusive in their nature, so it is not unusual to have multiple firms working on the same search. Companies often contract multiple recruitment firms in order to maximize the number of resumes received. Additionally, companies often conduct their own search or recruit from within during this effort. If you are looking to hire a lower-level employee, there are benefits to choosing a contingent recruitment firm, such as cost savings in the short term. If you want to hire a manager, an executive-level leader, or a board member, a retained recruitment firm is your best option.</p>
            </div>
          </div>
        </div>
        <div className="px-2 pt-12 text-company-800">
          <h1 className="pb-4 text-2xl text-center">Executive Search</h1>
          <p>Leadership requirements continue to evolve, but the need for high-performing executives remains constant. Identifying and attracting this talent requires a rigorous approach to assessing executives’ ability to be successful in specific roles and situations, a thorough understanding of different industries and functional roles, unrivaled access to senior executives and impeccable judgment.  We combine deep industry and functional knowledge with a proven executive search and assessment process to help you find leaders who best fit your organization’s unique needs and will have a lasting impact.</p>
        </div>
        <div className="px-2 pt-12 text-company-800">
          <h1 className="pb-4 text-2xl text-center">Consulting Services</h1>
          <p>People, relationships, knowledge, and collaboration – these words are the soul of what we do and the heart of who we are. We help organizations increase shareholder value by getting the most of their greatest assets; people and technology. Our talented experts sit at the intersection of business, technology, and enablement and as a result our business consulting division is well-positioned to partner with you to create measurable goals. We focus on achieving quick and actionable results that demonstrate value to your stakeholders. Whether providing capital GIS infrastructure planning, transformational change management portfolio/program management, business intelligence and data analytics or project delivery support.</p>
        </div>
      </div>
    </div>
  );
};

export default Services;
