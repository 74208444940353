import React from "react";
import { Link } from 'react-router-dom';
import Wind from "../assets/wind-turbine.svg";
import Performance from "../components/Performance";
import NewsCard from "../components/NewsCard";

const Home = () => {
  const news = [
    {
      image: "aquisition.svg",
      title: "Capital Search Inc. Announces Acquisition of Spaulding Associates",
      summary: "Capital Search Inc. acquired Spaulding Associates, expanding its recruitment services and strengthening its position in the engineering and technology sectors.",
      link: "/files/Immediate Release Capital Search.pdf",
    },
  ];

  return (
    <div className="text-company-800 md:mx-8">
      <div className="min-h-screen flex flex-wrap items-center justify-center space-x-8 px-8 md:flex-nowrap">
        <div className="pb-2 w-full text-center md:w-1/2">
          <h1 className="text-3xl text-bold font-bold mb-4 md:text-5xl">
            Capital Search, Inc.
          </h1>
          <p className="my-2 text-xs text-company-gold-600 md:text-lg">
            Client Focused, Candidate Driven.
          </p>
          <div className="flex justify-center h-12 md:h-16">
            <a href="#our-story" className="mx-2 my-2 px-4 py-2 scroll-smooth rounded-lg text-xs text-white bg-company-800 hover:bg-white hover:text-company-800 border-2 border-company-800 md:text-lg">
              Explore More
            </a>
            <Link to="/positions" className="mx-2 my-2 px-4 py-2 flex items-center space-x-2 text-sm text-company-gold-600 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:text-company-gold-800 duration-300 md: text-lg" href="/positions">
              <span>See Positions</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
              </svg>
            </Link>
          </div>
          <div>
            <p className="text-left mt-8 text-xs text-company-800 md:text-lg">
              Capital Search, Inc. is a national recruiting firm that specializes in the Engineering Industry. Our focus is to predominantly recruit the top talent in the power delivery sector, and we are fortunate to serve as a trusted partner to many of the leading engineering firms throughout the country. Combined we have over 40 years of technical recruiting experience within Power Delivery and AEC sectors. Our mission remains clear - to serve in the best interest of our clients and the candidates we represent.</p>
          </div>
        </div>
        {/* Image Section */}
        <div className="w-full md:w-1/2">
          <img src={Wind} alt="Wind Turbine" className="max-w-full h-auto" />
        </div>
      </div>
      <div id="our-story" className="mt-8 mx-4 flex flex-col items-center justify-center md:mt-4">
        <h1 className="text-4xl py-4 px-4">
          <span>Our Story</span>
        </h1>
        <p className="py-4 text-xs md:text-lg">
          In 2012, I came home late from work and told my wife that I wanted to start my own business. I had a great job, one that I could have stayed at for a long time. But, I knew that I had so much passion for recruiting and that I didn’t want to do it for someone else anymore - I wanted to do it for me. Going out on my own was an extremely serious risk for me and my family - we had just put our house up for sale (back when the housing market was less than ideal), and planned on buying a new house closer to my wife’s family. My wife was, and still is, a stay-at-home Mom. We have twin boys, who were 2 at the time. I wanted to build my own company the right way, and do it the way I firmly believed in. Within a month, I was off and running. We closed on the new house the third day I had started Capital Search. While I was either naïve or overly optimistic - probably a little bit of both - I knew it had to work. It took several months to sell our house. I had zero income that entire time, paying two mortgages. I knew that if I worked hard it was going to pay off. And it did.
        </p>
        <p className="py-4 text-xs md:text-lg">
          I’m so proud of what Capital Search has grown into. More importantly, I’m proud of how we’ve done it. Recruiting can be a very unethical business, but looking out for the best interest of our clients and our candidates is something we do not take lightly. We set the bar high with the candidates we pursue and choose to represent. We align ourselves with companies that share the same values as us to create a true partnership. I strongly express how we are dealing with people’s livelihoods, careers, and families. It’s extremely important to me and everyone at Capital Search, and it’s a principle that we never waiver on. It’s how we base every decision at the company - what’s right for the client, and what’s right for the candidate. It’s an absolute privilege to get to do this for a living.        </p>
        <p className="w-full flex justify-end mr-40 py-4 italic text-company-800 text-xs md:text-lg">
          - Dave Pohlman, President
        </p>
      </div>
      <Performance />
      <div className="mt-12 flex justify-center">
        <h1 className="text-4xl py-8 px-4">
          <span>News and Insights</span>
        </h1>
      </div>
      <div className="p-8 grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {news.map((item, index) => (
          <NewsCard
            key={index}
            image={item.image}
            title={item.title}
            summary={item.summary}
            link={item.link}
          />
        ))}
      </div>
    </div>
  );
};

export default Home;
