import React, { useState } from 'react';

const EmployeeCard = ({ employee }) => {
  const [isBioExpanded, setIsBioExpanded] = useState(false);

  // Toggle bio visibility
  const toggleBio = () => {
    setIsBioExpanded(!isBioExpanded);
  };

  return (
    <div className="w-80 rounded overflow-hidden shadow-xl bg-white m-6">
      {/* Employee Picture */}
      <img
        className="w-full h-80 object-cover aspect-w-1 aspect-h-1"
        src={`/images/${employee.image}`}
        alt=''
      />

      <div className="p-4">
        {/* Employee Name and Title */}
        <div className="text-xl font-semibold text-company-900">{employee.name}</div>
        <div className="text-sm text-company-gold-800">{employee.title}</div>

        {/* Contact Info */}
        <div className="mt-4 space-y-2">
          <div className="flex items-center text-sm text-company-900">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 pr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
            </svg>
            <span className='underline text-company-400'>
              <a href={"tel:+" + employee.phone}>{employee.phone}</a>
            </span>
          </div>
          <div className="flex items-center text-sm text-company-900">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 pr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
            </svg>
            <span className='underline text-company-400'>
              <a href={"mailto:" + employee.email}>{employee.email}</a>
            </span>
          </div>
        </div>

        {/* Bio with Expandable Option */}
        <div className="mt-4">
          <p
            className={`text-sm text-company-900 ${isBioExpanded ? 'block' : 'line-clamp-3' // Limiting to 3 lines if not expanded
              }`}
          >
            {employee.bio}
          </p>
          <button
            onClick={toggleBio}
            className="underline text-company-700 text-sm mt-2 hover:underline"
          >
            {isBioExpanded ? 'Show less' : 'Read more'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;
