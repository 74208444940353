import React from 'react';

const Contact = () => {

  return (
    <div className="mx-auto p-8">
      <h1 className="text-4xl font-bold mb-4">Contact Us</h1>
      <p className="mb-8 text-lg text-company-600">
        Have questions? Please contact one of our team members.
      </p>
      <div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2972.232338914872!2d-88.01953172417487!3d41.8448256674972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e52736160f5f3%3A0xf702d797634cf06d!2s2200%20S%20Main%20St%2C%20Lombard%2C%20IL%2060148!5e0!3m2!1sen!2sus!4v1736124101904!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="address-cap-search"></iframe>
      </div>
    </div>
  );
};

export default Contact;
